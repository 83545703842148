<template>
  <div class="bar-chart-warp">
    <p class="x-text">单位/月</p>
    <p class="y-text">单位/千</p>
    <canvas id="payChart"></canvas>
  </div>
</template>
<script>
import F2 from '@antv/f2';
export default {
  data() {
    return {
      chart: {}
    };
  },
  props: {
    list: {
      type: Array,
      default: () => {
        return [];
      },
      require: true
    }
  },
  watch: {
    list: {
      handler(val) {
        this.chart.clear(); // 清除上一次数据
        this.drawBarChart();
      }
    }
  },
  methods: {
    drawBarChart() {
      // Step 1: 创建 Chart 对象
      this.chart = new F2.Chart({
        id: 'payChart',
        pixelRatio: window.devicePixelRatio // 指定分辨率
      });

      // Step 2: 载入数据源
      this.chart.source(this.list);

      // Step 3：创建图形语法，绘制柱状图，由 genre 和 sold 两个属性决定图形位置，genre 映射至 x 轴，sold 映射至 y 轴
      this.chart
        .interval()
        .position('month*amountNum')
        .color('#FE730E');

      // Step 4: 渲染图表
      this.chart.render();
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.drawBarChart();
    });
  }
};
</script>
<style lang="less" scoped>
.bar-chart-warp {
  position: relative;
  canvas {
    width: 100%;
    height: 100%;
  }
  .x-text {
    position: absolute;
    right: 8px;
    bottom: -3px;
    font-size: 10px;
    font-weight: 400;
    text-align: left;
    color: #999999;
  }
  .y-text {
    position: absolute;
    left: 12px;
    top: 3px;
    font-size: 10px;
    font-weight: 400;
    text-align: left;
    color: #999999;
  }
}
</style>
