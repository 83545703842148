<template>
  <div class="jt-warp">
    <van-pull-refresh v-model="isLoading" success-text="刷新成功" @refresh="onRefresh">
      <div class="top-date" @click="timeShow = true">
        <p>{{ nowTimer }}<van-icon name="arrow-down" /></p>
      </div>
      <div class="finish-circle">
        <p class="title-group"><i></i>借/欠款完成进度</p>
        <van-grid :border="false" :column-num="2">
          <van-grid-item>
            <van-circle
              class="incomeColor"
              layer-color="#E6E5EB"
              color="#31DB90"
              size="82px"
              stroke-width="60"
              v-model="incomeCurrentRate"
              :rate="incomeRate"
              :speed="100"
              :text="incomeText"
            />
            <div class="percentage-group">
              <strong><span>¥</span>{{ incomeAmount1 }}</strong>
              <p>借款收回百分比</p>
            </div>
          </van-grid-item>
          <van-grid-item>
            <van-circle
              class="payColor"
              layer-color="#E6E5EB"
              color="#FE730E"
              size="82px"
              stroke-width="60"
              v-model="payCurrentRate"
              :rate="payRate"
              :speed="100"
              :text="payText"
            />
            <div class="percentage-group">
              <strong><span>¥</span>{{ payAmount1 }}</strong>
              <p>欠款已还百分比</p>
            </div>
          </van-grid-item>
        </van-grid>
      </div>
      <div class="circle-content">
        <p class="title-group"><i></i>借/欠款柱状图</p>
        <div class="circle-group">
          <div class="income-money">
            <strong><span>¥</span>{{ incomeAmount2 }}</strong>
            <p><i></i>借款（元）</p>
          </div>
          <div class="income-circle">
            <income-bar-chart :list="incomeData" />
          </div>
        </div>
        <div class="circle-group">
          <div class="pay-money">
            <strong><span>¥</span>{{ payAmount2 }}</strong>
            <p><i></i>欠款（元）</p>
          </div>
          <div class="pay-circle">
            <pay-bar-chart :list="payData" />
          </div>
        </div>
      </div>
    </van-pull-refresh>

    <van-action-sheet v-model="timeShow" :close-on-click-overlay="false">
      <van-datetime-picker
        v-model="currentDate"
        type="year-month"
        title="选择年月"
        :formatter="formatter"
        @confirm="confirmTimer"
        @cancel="timeShow = false"
      />
    </van-action-sheet>
  </div>
</template>
<script>
import { Grid, GridItem, Circle, Icon, ActionSheet, DatetimePicker, PullRefresh } from 'vant';

import incomeBarChart from '@/components/Charts/IncomeBarChart.vue';
import payBarChart from '@/components/Charts/PayBarChart.vue';

import { showLoading, hideLoading } from '@/utils/VantUtils';
import { handlerEror } from '@/utils/Tools';

import { saveCache } from '@/utils/Tools';

import Http from '@/utils/Http.js';

const barChartUrl = 'cloud/ec/veritable/chart';

export default {
  components: {
    vanGrid: Grid,
    vanGridItem: GridItem,
    vanCircle: Circle,
    vanIcon: Icon,
    vanActionSheet: ActionSheet,
    vanDatetimePicker: DatetimePicker,
    vanPullRefresh: PullRefresh,
    incomeBarChart,
    payBarChart
  },
  data() {
    return {
      incomeCurrentRate: 0,
      payCurrentRate: 0,
      incomeRate: 0,
      payRate: 0,
      incomeText: '',
      payText: '',
      timeShow: false, //时间选择
      currentDate: '',
      nowTimer: '',
      incomeData: [],
      payData: [],
      incomeAmount1: 0,
      incomeAmount2: 0,
      payAmount1: 0,
      payAmount2: 0,
      isLoading: false //下拉刷新
    };
  },
  computed: {
    // incomeText() {
    //   return this.incomeCurrentRate.toFixed(0) + '%';
    // },
    // payText() {
    //   return this.payRate.toFixed(0) + '%';
    // }
  },
  methods: {
    onRefresh() {
      let query = this.$route.query;
      saveCache(query);
      this.nowTimer = new Date().getFullYear() + '-' + (new Date().getMonth() + 1);
      this.currentDate = new Date();
      this.getData(this.nowTimer);
    },
    formatter(type, val) {
      if (type === 'year') {
        return `${val}`;
      } else if (type === 'month') {
        return `${val}`;
      }
      return val;
    },
    getData(time) {
      showLoading();
      Http.get(barChartUrl, { time })
        .then((data) => {
          hideLoading();
          this.isLoading = false;
          this.incomeRate = data.result[0].percentNum;
          this.payRate = data.result[1].percentNum;
          this.incomeText = data.result[0].percent;
          this.payText = data.result[1].percent;
          this.incomeData = data.result[2].list;
          this.payData = data.result[3].list;
          this.incomeAmount1 = data.result[0].amount;
          this.incomeAmount2 = data.result[2].amount;
          this.payAmount1 = data.result[1].amount;
          this.payAmount2 = data.result[3].amount;
        })
        .catch((err) => {
          this.isLoading = false;
          hideLoading();
          handlerEror(err);
        });
    },
    confirmTimer(timer) {
      let year = new Date(timer).getFullYear();
      let month = new Date(timer).getMonth() + 1;
      if (month < 10) {
        month = '0' + month;
      }
      this.nowTimer = year + '-' + month;
      this.getData(this.nowTimer);
      this.timeShow = false;
    }
  },
  created() {
    window.refresh = this.onRefresh;
    this.$nextTick(() => {
      this.onRefresh();
    });
  }
};
</script>
<style lang="less" scoped>
.jt-warp {
  padding: 12px;
  background: #f8f8f8;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  .van-pull-refresh {
    height: 100%;
  }
  .top-date {
    text-align: center;
    margin-bottom: 23px;
    margin-top: 9px;
  }
  .title-group {
    font-size: 14px;
    font-weight: 500;
    text-align: left;
    color: #121212;
    line-height: 20px;
    display: flex;
    align-items: center;
    margin: 10px 0;
    i {
      display: inline-block;
      width: 3px;
      height: 14px;
      background: #ff804a;
      border-radius: 2px;
      margin-right: 5px;
    }
  }
  .finish-circle {
    .percentage-group {
      text-align: center;
      margin-top: 15px;
      strong {
        font-size: 20px;
        font-weight: 700;
        text-align: left;
        color: #121212;
        display: inline-block;
        line-height: 34px;
        & > span {
          font-size: 10px;
        }
      }
      p {
        font-size: 14px;
        font-weight: 400;
        text-align: left;
        color: #121212;
      }
    }
    .incomeColor {
      /deep/ .van-circle__text {
        color: #31db90;
        font-size: 11px;
        font-weight: 500;
      }
    }
    .payColor {
      /deep/.van-circle__text {
        color: #fe730e;
        font-size: 11px;
        font-weight: 500;
      }
    }
    .van-grid {
      padding-left: 0 !important;
      .van-grid-item {
        border-radius: 4px !important;
        overflow: hidden;
      }
      .van-grid-item:first-child {
        padding-right: 9px !important;
      }
      .van-grid-item:last-child {
        padding-right: 0 !important;
        padding-left: 8px !important;
      }
    }
  }
  .circle-content {
    & > .circle-group {
      height: 118px;
      border-radius: 10px;
      background: #ffffff;
      margin-bottom: 10px;
      padding: 10px;
      display: flex;
      .income-money {
        font-size: 20px;
        font-weight: 700;
        text-align: left;
        color: #31db90;
        line-height: 24px;
        margin-right: 25px;
        display: flex;
        flex-flow: column;
        justify-content: center;
        flex: 0;
        min-width: 80px;
        padding-left: 4px;
        p {
          i {
            background: #31db90;
          }
        }
      }
      .pay-money {
        font-size: 20px;
        font-weight: 700;
        text-align: left;
        color: #fe730e;
        line-height: 24px;
        margin-right: 25px;
        display: flex;
        flex-flow: column;
        justify-content: center;
        flex: 0;
        min-width: 80px;
        padding-left: 4px;
        p {
          i {
            background: #fe730e;
          }
        }
      }
      strong {
        & > span {
          font-size: 12px;
        }
      }
      p {
        font-size: 12px;
        font-weight: 500;
        text-align: left;
        color: #121212;
        i {
          display: inline-block;
          width: 6px;
          height: 6px;
          border-radius: 50%;
          margin-right: 8px;
        }
      }
      .income-circle {
        // background: chartreuse;
        flex: 1;
      }
      .pay-circle {
        // background: chartreuse;
        flex: 1;
      }
    }
  }
}
</style>
